import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import constants from "../../constants";
import { getWithAuth } from "../../services/authentication";
import Recognition from "./recognition";
import EmptyScreen from "../common/empty/emptyScreen";
import leaveEmpty from "../../assets/leave-empty.svg";
import Spinner from "../common/spinner/spinner";
import emptyAnnouncement from "./../../assets/emptyAnnouncement.svg";
import Alphabet from "../common/alphabet/alphabet";
import { fn_firstcharacter } from "../../utility/utility";
import { Divider } from "primereact/divider";
import { getAnnouncements } from "../../store/features/HumanResource/announcement";
import { useDispatch } from "react-redux";

const dataFormate = (date) => {
  return new Date(date).toLocaleTimeString("en-uk", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export default function Announcement() {
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [announcements, setAllAnnouncement] = useState([]);
  const [todayLeave, setTodayLeave] = useState([]);
  const [tomorrowLeave, setTomorrowLeave] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getAllAnnouncement();
    getLeaveEmployees();
  }, []);

  async function getAllAnnouncement() {
    try {
      var date = new Date();
      var from = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).toLocaleDateString("en-us");
      var to = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).toLocaleDateString("en-us");
      const { payload } = await dispatch(getAnnouncements({ fromDate: from, toDate: to }));
      setAllAnnouncement(payload?.announcements || []);
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: "",
        life: 3000,
      });
    }
  }

  async function getLeaveEmployees() {
    const employeeLeaveURL = constants.API.LEAVE_REQUEST.GET_WHO_ON_LEAVE;
    const response = await getWithAuth(employeeLeaveURL, token);
    setTodayLeave(response.data.today);
    // setTodayLeaveCount(response.data.today.length);
    setTomorrowLeave(response.data.tomorrow);
  }

  return (
    <div>
      <div className="grid">
        {/* Announcements */}
        <div className="col-5">
          <div className="announcement-border pr-5">
            <br />

            <div className="announcement-header-font flex justify-content-center">
              Upcoming Announcements
            </div>
            {announcements.length > 0 ? (
              <div className="scrollable">
                {announcements.map((announcement, id) => {
                  return (
                    <div key={id}>
                      <div className="announcement-header-font mt-2 ml-4 mb-4">
                        {announcement.title}
                      </div>
                      <h5
                        className="mt-2 ml-5 mb-3"
                        dangerouslySetInnerHTML={{
                          __html: announcement.description,
                        }}
                      ></h5>
                      <div className="flex  announcement-time ">
                        <div className="col-6 flex align-items-center">
                          <i
                            className="pi pi-calendar-times ml-2"
                            style={{ fontSize: "1.5em" }}
                          />
                          <span className="ml-2 mt-1">
                            {dataFormate(announcement.date)}
                          </span>
                        </div>
                      </div>
                      <Divider />
                      <br />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no-leave-tomorrow">
                <div className="flex justify-content-center ml-7 mt-6">
                  <img src={emptyAnnouncement} />
                </div>
                <div className="announcement-time flex justify-content-center mt-3 ml-7">
                  No Announcements
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Recognition */}
        <div className="col-3">
          <Recognition />
        </div>

        {/* tomorrow leave */}
        <div className="grid flex justify-content-center">
          {spinner && <Spinner value={spinner} />}
        </div>

        {tomorrowLeave && tomorrowLeave.length > 0 && (
          <div className="col-4">
            <div className="ml-5">
              <div className="leave-scroll tomorrow-leave-box">
                <div className="announcement-time mt-2">Tomorrow</div>
                {tomorrowLeave.map((emp, index) => (
                  <div className="flex column-gap-3 p-2 " key={index}>
                    <div className="flex  align-items-center ">
                      {emp.userDetails.profilePic ? (
                        <img
                          className="img-size"
                          src={emp.userDetails.profilePic}
                          alt="profilepic"
                        />
                      ) : (
                        <Alphabet
                          letter={fn_firstcharacter(emp.userDetails.fullName)}
                          borderRadius="20%"
                        />
                      )}
                    </div>

                    <div className="flex flex-column">
                      <div className="announcement-time">
                        {emp.userDetails.fullName}
                      </div>
                      <div className="leave-reason-font">{emp.reason}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {!spinner && tomorrowLeave !== {} && tomorrowLeave.length === 0 && (
          <div className="leave-scroll no-leave-tomorrow">
            <div className="announcement-header-font flex justify-content-center">
              Who's Away Tomorrow
            </div>
            <EmptyScreen
              img={leaveEmpty}
              description={constants.EMPTY_SCREEN_TEXT[5].description}
            />
          </div>
        )}
      </div>
    </div>
  );
}
