import React, { useEffect, useReducer, useRef } from "react";
import "./createSpace.scss";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import InputField from "../../common/inputtext/input-field";
import Button from "../../common/button/button";
import Checkboxes from "../../common/checkbox/checkbox";
import { WIKI_CHECKBOX_SPACE_ARRAY } from "../../../utility/utility";
import {
  getAllPageApi,
  wikiCreateSpaceApi,
} from "../../../store/features/wiki";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { MIDDLEWARE_STATUS } from '../../../constants';

export default function CreateSpace({ isCheck, visible }) {
  const { useDetails } = useSelector((store) => store.wiki);

  const initialState = {
    dispatch: useDispatch(),
    userName: "",
    selectedCheckBox: "Private",
    checkBoxArray: WIKI_CHECKBOX_SPACE_ARRAY,
    userData: useDetails,
  };
  const toast = useRef(null);
  const reducer = (state, action) => {
    switch (action.type) {
      case "userName":
        return { ...state, userName: action.payload };
      case "selectedCheckBoxValue":
        return { ...state, selectedCheckBox: action.payload };
      default:
        throw new Error("something went wrong!");
    }
  };

  const ACTION = {
    USERNAME: "userName",
    SELECTEDCHECKBOX: "selectedCheckBoxValue",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const _getAllPageApi = () => {
    if (useDetails) {
      state.dispatch(getAllPageApi({
        orgId: useDetails?.orgId,
        memberId: useDetails?.memberOrgId,
      })
      );
    }
  };

  const handleCreateSpace = async () => {
    const payload = {
      name: state.userName,
      private: Boolean(state.selectedCheckBox === "Private"),
    };

    state.dispatch(wikiCreateSpaceApi(payload)).then((res) => {
      if (res.meta.requestStatus === MIDDLEWARE_STATUS.FULFILLED) {
        toast.current.show({
          severity: "success",
          detail: res?.payload?.message,
          life: 3000,
        });
        dispatch({ type: ACTION.USERNAME, payload: "" });
        _getAllPageApi();
      }
      isCheck(false);
    }).catch((err) => {
      isCheck(false);
      toast.current.show({
        severity: "error",
        detail: err?.response?.data?.message,
        life: 3000,
      });
    })
  };

  const handleCheckboxChange = (item) => {
    dispatch({ type: ACTION.SELECTEDCHECKBOX, payload: item.label });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Create Space"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => isCheck(false)}
      >
        <div className="flex flex-column row-gap-3 pt-4">
          <InputField
            type="text"
            placeholder="Enter Name"
            label="Space Name"
            onChange={(e) =>
              dispatch({ type: ACTION.USERNAME, payload: e.target.value })
            }
            value={state.userName}
          />
          <div className="flex flex-column row-gap-2">
            <label>Privacy Settings</label>
            <div className="flex column-gap-4">
              {state.checkBoxArray.map((item, id) => {
                return (
                  <div className="flex column-gap-2" key={id}>
                    <Checkboxes
                      value={item.ischecked}
                      onChange={() => handleCheckboxChange(item)}
                      checked={item.label === state.selectedCheckBox}
                      name={item.name}
                    />
                    <label>{item.label}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-content-end align-items-center">
            <Button
              className=""
              label="Create"
              disabled={
                !state.userName ||
                state.checkBoxArray.every((x) => x.ischecked === false)
              }
              onClick={handleCreateSpace}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
