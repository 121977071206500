import axios from "axios";

export const post = async (url, data) => {
  const header = { "Content-Type": "application/json" };
  return axios.post(url, data, header);
};

export const postWithAuth = async (url, data, token) => {
  if (!token) token = localStorage.getItem('token');
  const header = {
    headers: { "Content-Type": "application/json", authorization: token },
  };
  return axios.post(url, data, header);
};

export const putWithAuth = async (url, data, token) => {
  if (!token) token = localStorage.getItem('token');
  const header = {
    headers: { "Content-Type": "application/json", authorization: token },
  };
  return axios.put(url, data, header);
}
export const deleteWithAuth = async (url, token) => {
  if (!token) token = localStorage.getItem('token');
  const header = {
    headers: { "Content-Type": "application/json", authorization: token },
  };
  return axios.delete(url, header);
}
export const patchWithAuth = async (url, data, token) => {
  if (!token) token = localStorage.getItem('token');
  const header = {
    headers: { "Content-Type": "application/json", authorization: token },
  };
  return axios.patch(url, data, header);
}



export const getWithAuth = async (url, token) => {
  if (!token) token = localStorage.getItem('token');
  const header = {
    headers: { "Content-Type": "application/json", authorization: token },
  };
  return axios.get(url, header);
};
// module.exports = { post }
