import React, { useEffect, useRef, useState } from "react";
import { Editor } from "primereact/editor";
import { editWikipage, getAllPageApi, getSubpage, getSubpageById } from "../../../store/features/wiki";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import Spinner from "../../common/spinner/spinner";
import { InputText } from "primereact/inputtext";
import { isEmptyArray } from "../../../utility/utility";
import { MIDDLEWARE_STATUS } from "../../../constants";

export default function AddWikiSpace({ selectedPage }) {

  const toast = useRef(null);
  const dispatch = useDispatch();

  const { subPageList, getAllPage, useDetails } = useSelector(
    (store) => store.wiki
  );
  const [updateItem, setUpdateItem] = useState({});
  const [loader, setloader] = useState(false);

  const toastMsg = ({ type, message }) => {
    toast.current.show({
      severity: type,
      summary: message,
      life: 3000,
    });
  };

  useEffect(() => {
    getPageContent();
  }, [selectedPage]);

  useEffect(() => {
    setUpdateItem(subPageList);
  }, [subPageList]);


  const submitHandler = async () => {
    setloader(true);
    const { content = "", name, _id } = updateItem;
    try {
      const response = await dispatch(editWikipage({ content, name, _id }));
      if (response.meta.requestStatus === MIDDLEWARE_STATUS.FULFILLED) {
        updateSubPage(response.payload?.result?.parentPageId || response.payload?.result?._id)
        getPageContent();
        toastMsg({ type: "success", message: response.payload?.message });
      }
      setloader(false);
    } catch (error) {
      setloader(false);
      toastMsg({ type: "error", message: error?.response?.data?.message || "Failed to edit wiki page" });
    }
  };

  const updateSubPage = (items) => {
    const payload = {
      orgId: useDetails?.orgId,
      selectedWikiPage: items,
      memberId: useDetails?.memberOrgId,
    };
    dispatch(getSubpage(payload));
  };

  const getStepDetail = (item) => {
    let step;
    if (item.isParent) step = `Wiki > ${item.name}`;
    else {
      const findParentName = getAllPage.filter((x) => x._id === item.parentPageId);
      if (isEmptyArray(findParentName)) step = `Wiki > ${item.name}`
      else step = `wiki > ${findParentName[0]?.name} > ${item.name}`;
    }
    return step;
  };

  const getPageContent = () => {
    setloader(true);
    const payload = {
      orgId: useDetails?.orgId,
      contentId: selectedPage._id,
    };
    dispatch(getSubpageById(payload)).then((res) => {
      setloader(false);
      if (!res.error) _getAllPageApi();
    });
  };

  const _getAllPageApi = () => {
    dispatch(
      getAllPageApi({
        orgId: useDetails?.orgId,
        memberId: useDetails?.memberOrgId,
      })
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Tooltip target=".wiki" />

      {loader && (
        <div className="flex align-items-center justify-content-center w-full h-full">
          <Spinner value={loader} />
        </div>
      )}

      {!loader && (
        <div className="flex flex-column row-gap-3 m-3">
          <div className="flex w-full align-items-center justify-content-between">
            <div className="scratchpad__nav">{getStepDetail(selectedPage)}</div>
            <span
              className="wiki"
              data-pr-tooltip="click here to save"
              data-pr-position="left"
            >
              <i
                className="pi pi-save"
                style={{ fontSize: "1.5rem", color: "black", cursor: "pointer" }}
                onClick={submitHandler}
              />
            </span>
          </div>
          <span className="text-lg font-bold">Page Name</span>
          <InputText
            name="name"
            value={!updateItem?.name ? "" : updateItem.name}
            placeholder="PageName"
            onChange={(e) =>
              setUpdateItem((prev) => {
                return { ...prev, ["name"]: e.target.value };
              })
            }
          />
          <Editor
            style={{ height: "60vh" }}
            value={!updateItem?.content ? "" : updateItem.content}
            onTextChange={(e) =>
              setUpdateItem((prev) => {
                return { ...prev, ["content"]: e.htmlValue };
              })
            }
          />
        </div>
      )}
    </>
  );
}
