import React, { useState } from "react";
import { fn_firstcharacter, replaceDotMethod } from "../../../utility/utility";
import Alphabet from "../alphabet/alphabet";

export default function MemberContainer({
  image,
  name,
  title,
  desc,
  borderRadius = "50%",
  padding = "1rem",
  titleFont = "14px",
  descFont = "14px",
}) {
  const [isImgError, setIsImgError] = useState(false);
  const imgErrorHandler = () => {
    setIsImgError(true);
  }
  return (
    <>
      <div className="flex column-gap-3" style={{ padding }}>
        <div className="flex justify-content-center align-items-center">
          {isImgError || !image ? (
            <Alphabet
              letter={fn_firstcharacter(name)}
              borderRadius={borderRadius}
            />
          ) :
            <img
              className="member-owner-image"
              src={image}
              alt="image"
              style={{ borderRadius: borderRadius }}
              onError={imgErrorHandler}
            />
          }
        </div>
        <div className="flex flex-column justify-content-center">
          <div style={{ color: "#252B30", fontSize: titleFont }}>{replaceDotMethod(title, 25)}</div>
          <div style={{ color: "#A4A8AE", fontSize: descFont }}>{desc}</div>
        </div>
      </div>
    </>
  );
}
