import React, { useState } from 'react'
import { fn_firstcharacter } from "../../../utility/utility";
import Alphabet from "../alphabet/alphabet";
import "./avatarGroup.scss";

function AvatarGroupItem({ item, size, width, height }) {
    const [isImgError, setIsImgError] = useState(false);

    const imageErrorHandler = () => setIsImgError(true);

    return (
        <div
            style={{ marginLeft: "-8px" }}
            data-pr-tooltip={item.name}
            data-pr-position="bottom"
            data-pr-classname="blue-tooltip "
            className="disabled-button cursor-pointer"
        >
            {isImgError || !item?.url ?
                <Alphabet
                    letter={fn_firstcharacter(item.name)}
                    width={width}
                    height={height}
                    fontSize={size ? "10px" : "14px"}
                    border="1px solid #FFFFFF"
                />
                :
                <img
                    src={item.url}
                    alt={item.name}
                    style={{
                        borderRadius: "50%",
                        border: "1px solid #FFFFFF",
                        width: "33px",
                        height: "33px",
                    }}
                    onError={imageErrorHandler}
                />
            }
        </div>
    )
}

export default AvatarGroupItem