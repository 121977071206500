import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "../../component/common/spinner/spinner";
import CreateSpace from "../../component/wiki/create-space-modal/createSpace";
import CreateWiki from "../../component/wiki/create-wiki/createWiki";
import PageTree from "../../component/wiki/page-tree/pageTree";
import { getAllPageApi, setUserDetails } from "../../store/features/wiki";

export default function Wiki() {

  const dispatch = useDispatch();
  const { getAllPage } = useSelector((store) => store.wiki);
  const [isCreateSpace, setIsCreateSpace] = useState(false);

  useEffect(() => {
    _getAllPageApi();
  }, []);

  const _getAllPageApi = () => {
    const currentUserDetails = JSON.parse(localStorage.getItem("user"))
    dispatch(setUserDetails(currentUserDetails))
    if (currentUserDetails) {
      dispatch(
        getAllPageApi({ orgId: currentUserDetails?.orgId, memberId: currentUserDetails?.memberOrgId })
      );
    }
  };

  return (
    <>
      {getAllPage && getAllPage?.length === 0 ? (
        <div className="" style={{ width: "100%", height: "85vh" }}>
          {/* CreateWiki componenet is Empty Screen for Wiki */}
          <CreateWiki isCheck={setIsCreateSpace} />
          {isCreateSpace && (
            <CreateSpace visible={isCreateSpace} isCheck={setIsCreateSpace} />
          )}
        </div>
      ) : (
        <>
          <PageTree />
        </>
      )}
    </>
  );

}
