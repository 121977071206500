import React, { useEffect, useState } from "react";
import "./avatarGroup.scss";
import { Tooltip } from "primereact/tooltip";
import AvatarGroupItem from "./avatarGroupItem";

export default function AvatarGroup({ item, size }) {
  const [width, setwidth] = useState("");
  const [height, setheight] = useState("");

  useEffect(() => {
    switch (size) {
      case "small":
        setwidth("25px");
        setheight("25px");
        break;
      case "medium":
        setwidth("33px");
        setheight("33px");
        break;
      case "large":
        setwidth("40px");
        setheight("40px");
        break;

      default:
        setwidth("33px");
        setheight("33px");
        break;
    }
  }, []);

  return (
    <>
      {item && item.length > 0 ? (
        <div className="avatar__group">
          <Tooltip target=".disabled-button" />
          {item.slice(0, 3).map((item, id) => {
            return <AvatarGroupItem key={id} item={item} size={size} width={width} height={height} />
          })}
          {Math.sign(item.length - 3) === 1 ? (
            <div style={{ marginLeft: "-8px" }}>
              <div
                style={{
                  width: width,
                  height: height,
                  border: "1px solid #FFFFFF",
                  backgroundColor: "rgb(90, 120, 238)",
                  borderRadius: "50%",
                }}
                className="flex align-items-center justify-content-center"
              >
                <span
                  style={{ fontSize: size ? "10px" : "14px", color: "white" }}
                  className="font-bold"
                >{`+ ${item.length - 3}`}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
