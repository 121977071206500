import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "primereact/badge";
import { Menu } from "primereact/menu";
import { getNotications } from "../../../store/features/UserDetails/UserDetails";
import {
  fn_firstcharacter,
  isAdminAccess,
  navLink,
} from "../../../utility/utility";
import Notification from "../notification/notification";
import Image from "../../../utility/image";
import { OverlayPanel } from "primereact/overlaypanel";
import MyProfile from "../profile/myProfile";
import Alphabet from "../alphabet/alphabet";

const Navbar = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState("");
  const [myProfileDialog, setMyProfileDialog] = useState(false);
  const { memberDetails, notifications, skip, limit } = useSelector(
    (store) => store.memberDetails
  );
  const op = useRef(null);
  const menu = useRef(null);

  const usersAdmin = isAdminAccess("Settings");

  useEffect(() => {
    const path = `/${location.pathname.split("/")[1]}`;
    switch (path) {
      case "/":
        setActive("Dashboard");
        break;
      case "/hr":
        setActive("Human Resources");
        break;
      case "/timesheet":
        setActive("Timesheet");
        break;
      case "/wiki":
        setActive("Wiki");
        break;
      case "/users":
        setActive("Users");
        break;
      case "/project":
        // changed project to projects
        setActive("Projects");
        break;
      case "/scratchpad":
        setActive("Scratchpad");
        break;
      default:
        setActive("Dashboard");
        break;
    }
  }, []);

  const clickHandler = ({ target }) => {
    setActive(target.innerHTML);
    navLink(target.innerHTML, Navigate);
  };

  useEffect(() => {
    dispatch(getNotications({ skip, limit }));
  }, []);

  let items = [
    {
      label: "Profile",
      icon: "pi pi-fw pi-user",
      command: () => {
        setMyProfileDialog(!myProfileDialog);
      },
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();
      },
    },
  ];

  return (
    <div className=" flex box-shadow nav-bar-sticky  justify-content-center w-full">
      {/* Nav Bar */}
      <div className="flex justify-cotent-between column-gap-4 mt-4" style={{ width: "95%" }}>
        <div
          style={{ width: "5%" }}
          className=" flex align-item-center justify-content-center"
        >
          <img width={34} height={30} src={Image.logo} alt="squadzen" />
        </div>
        <div className="flex " style={{ width: "55%" }}>
          <div className="flex align-content-center column-gap-4">
            {memberDetails?.routes?.map((route, id) => {
              if (route !== "Settings") {
                return (
                  <div
                    key={id}
                    onClick={clickHandler}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                    }}
                    className={
                      active === `${route === "Project" ? "Projects" : route}`
                        ? "active-nav white-space-nowrap "
                        : "nav-value  white-space-nowrap"
                    }
                  >
                    {route === "Project" ? "Projects" : route}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div
          style={{ width: "40%" }}
          className="flex justify-content-end  column-gap-4"
        >
          {usersAdmin && (
            <img
              width={20}
              height={20}
              className="hover"
              onClick={() => {
                Navigate("/settings/role");
              }}
              src={Image.settingIcon}
              alt="settingIcon"
            />
          )}
          <div className="relative">
            <img
              onClick={(e) => op.current.toggle(e)}
              src={Image.notify}
              width={20}
              height={20}
              className="cursor-pointer"
            />
            {notifications?.length > 0 && (
              <Badge
                severity="danger"
                className="absolute"
                style={{ left: "11px", top: "-2px" }}
              />
            )}
          </div>

          <OverlayPanel ref={op}>
            {notifications?.length > 0 && notifications ? (
              <Notification item={notifications} />
            ) : (
              <div className="flex flex-column row-gap-3  notify__container align-items-center">
                <div className="title-notification">No Notification</div>
                <img
                  src={Image.emptyNotification}
                  alt="nofication"
                  width={50}
                  height={50}
                />
              </div>
            )}
          </OverlayPanel>

          {memberDetails.profilePic ? (
            <img
              className="cursor-pointer border-circle"
              src={memberDetails?.profilePic}
              onClick={(event) => menu.current.toggle(event)}
              alt="member"
              width={23}
              height={23}
            />
          ) : (
            <div onClick={(event) => menu.current.toggle(event)}>
              <Alphabet
                letter={fn_firstcharacter(memberDetails?.fullName)}
                width="23px"
                height="23px"
                fontSize="12px"
              />
            </div>
          )}
          <Menu model={items} popup ref={menu} />
          <MyProfile
            myProfileDialog={myProfileDialog}
            setMyProfileDialog={setMyProfileDialog}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
