import React, { useState } from 'react';
import Alphabet from "../common/alphabet/alphabet";
import { fn_firstcharacter } from '../../utility/utility';


function TableImageComponent({ src, text }) {
    const [isImgError, setIsImgError] = useState(false);

    const imageErrorHandler = () => setIsImgError(true);

    const imageStyle = {
        width: "33px",
        borderRadius: "50%",
        height: "33px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    return (
        <>
            {isImgError || !src
                ? <Alphabet letter={fn_firstcharacter(text)} />
                : <img className="flex" src={src} onError={imageErrorHandler} style={imageStyle} />
            }
        </>
    )
}

export default TableImageComponent