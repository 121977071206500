import React, { useRef, useState } from "react";
import { PriorityChip } from "../common/chip/chip";
import { Avatar } from "primereact/avatar";
import { fn_firstcharacter, replaceDotMethod } from "../../utility/utility";
import Alphabet from "../common/alphabet/alphabet";
import { Tooltip } from "primereact/tooltip";
import UpdateTaskDialog from "./tasks/updateTaskDialog";
import { useDispatch } from "react-redux";
import { setActiveTask } from "../../store/features/Projects/Task";

export default function StoryCard({ story, active }) {
  const dispatch = useDispatch();
  const [updateDialog, setUpdateDialog] = useState(false);
  const [isImgError, setIsImgError] = useState(false);

  const handleImageError = (e) => {
    setIsImgError(true);
  };

  const handleAssigneeName = ({ assignee }) => {
    const name = assignee?.basic.fullName ? assignee.basic.fullName : "";
    return (
      <div className="task" data-pr-tooltip={name} data-pr-position="right">
        {assignee ? (
          <div>
            {isImgError || !assignee?.basic?.profilePic ? (
              <Alphabet
                letter={fn_firstcharacter(name)}
                width="32px"
                height="32px"
              />
            ) : (
              <img
                className="img-size"
                src={assignee?.basic.profilePic}
                onError={handleImageError}
              />
            )}
          </div>
        ) : (
          <div className="task">
            <Avatar
              icon="pi pi-user"
              className="cursor-pointer"
              size="small"
              shape="circle"
            />
          </div>
        )}
      </div>
    );
  };

  const handleUpdateTask = () => {
    dispatch(setActiveTask(story));
    setUpdateDialog(!updateDialog);
  };

  return (
    <>
      {updateDialog && (
        <UpdateTaskDialog
          taskDialog={updateDialog}
          setUpdateTaskDialog={setUpdateDialog}
        />
      )}
      <div
        className="project-card p-2 m-2"
        style={active ? { opacity: 0.7 } : {}}
        onClick={handleUpdateTask}
      >
        <Tooltip target=".task" />
        <div className="flex flex-column row-gap-2">
          <div className="flex column-gap-2">
            <div className="colorcard1" />
            <div className="colorcard2 " />
          </div>
          <div className="project-title">
            <h1>{story?.taskId}</h1>
            <p>{replaceDotMethod(story?.title, 30)}</p>
          </div>
          <div className="flex justify-content-between align-items-center">
            <PriorityChip label={story.priority} />
            {handleAssigneeName(story)}
          </div>
        </div>
      </div>
    </>
  );
}
